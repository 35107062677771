// importation de nos components

import Scrolly from './components/Scrolly';
import Header from './components/Header';
import Youtube from './components/Youtube';
import Carousel from './components/Carousel';

// export default class ComponentFactory // permet d'exporter la classe ComponentFactory

export default class ComponentFactory {
  constructor() {
    // Cette partie du code crée un objet componentList contenant des références à différentes classes de composantes importées.
    // Elle permet à la classe ComponentFactory d'instancier des composantes en fonction des éléments HTML avec l'attribut data-component lors de l'appel de la méthode init().

    this.componentList = {
      Scrolly,
      Header,
      Youtube,
      Carousel,
    };

    this.init();
  }

  // Cette partie du code init() parcourt tous les éléments HTML avec l'attribut data-component, puis instancie les composantes correspondantes à partir de la liste componentList,
  // en utilisant le nom du composant récupéré depuis l'attribut data-component. Si aucun composant correspondant n'est trouvé dans la liste, un message d'erreur est affiché dans la console.

  init() {
    // parcourt tous les éléments HTML avec l'attribut data-component

    const components = document.querySelectorAll('[data-component]');

    //  en utilisant le nom du composant récupéré depuis l'attribut data-component

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      // instancie les composantes correspondantes à partir de la liste componentList

      if (this.componentList[componentName]) {
        new this.componentList[componentName](element);
      }

      // Si aucun composant correspondant n'est trouvé dans la liste, un message d'erreur est affiché dans la console.
      else {
        console.log(`La composante ${componentName} n'existe pas!`);
      }
    }
  }
}
