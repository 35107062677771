// permet d'importer autres fichiers javascript dans le fichier courant

import Icons from './utils/Icons';
import LocomotiveScroll from 'locomotive-scroll';

import ComponentFactory from './componentFactory';

class Main {
  constructor() {
    this.init();
  }

  init() {
    Icons.load();

    // Ajouter la classe has-js
    document.documentElement.classList.add('has-js');

    const locomotiveScroll = new LocomotiveScroll();

    const scroll = new LocomotiveScroll({
      el: document.querySelector('[data-scroll]'),
      smooth: true,
    });

    // on appel le componentFactory dans le Main
    new ComponentFactory();
  }
}

new Main();
